var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('select-warehouse-model',{attrs:{"label":_vm.$t('labels.warehouse'),"single-line":false},on:{"change":_vm.onWarehouseChange},model:{value:(_vm.id_warehouse),callback:function ($$v) {_vm.id_warehouse=$$v},expression:"id_warehouse"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('input-qr-scan-model',{ref:"inputQr",staticClass:"mb-5",attrs:{"label":_vm.$t('labels.qr_sku_barcode'),"disabled":!_vm.id_warehouse},on:{"keyupEnter":_vm.onCheck},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-simple-table',{staticClass:"table-padding-2 mt-3",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"role":"columnheader"}},[_vm._v(" "+_vm._s(_vm.$t("labels.suitable_basket"))+" ")]),_c('th',{staticClass:"text-center",attrs:{"role":"columnheader"}},[_vm._v(" "+_vm._s(_vm.$t("labels.employee"))+" ")]),_c('th',{staticClass:"text-center",attrs:{"role":"columnheader"}},[_vm._v(" "+_vm._s(_vm.$t("labels.missing_quantity"))+" ")])])]),_c('tbody',_vm._l((_vm.baskets),function(item,index){return _c('tr',{key:`${index}_${item.id}`,staticClass:"text-center",class:{
                'error--text font-weight-medium':
                  item.missing_quantity && item.missing_quantity > 0,
              }},[_c('td',[_vm._v(_vm._s(item.basket_code))]),_c('td',[_vm._v(" "+_vm._s(item.employee_name)+" "),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.id_pickup_command)+" - "+_vm._s(_vm.formatDateTime(item.pickup_at))+" ")])]),_c('td',[_vm._v(_vm._s(item.id_find_goods ? "Yes" : "No"))])])}),0)]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-simple-table',{staticClass:"table-padding-2 mt-3",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"role":"columnheader"}},[_vm._v(" "+_vm._s(_vm.$t("labels.sku"))+" ")]),_c('th',{staticClass:"text-center",attrs:{"role":"columnheader"}},[_vm._v(" "+_vm._s(_vm.$t("labels.image"))+" ")]),_c('th',{staticClass:"text-center",attrs:{"role":"columnheader"}},[_vm._v(" "+_vm._s(_vm.$t("labels.goods"))+" ")]),_c('th',{staticClass:"text-center",attrs:{"role":"columnheader"}},[_vm._v(" "+_vm._s(_vm.$t("labels.quantity"))+" ")])])]),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:`${index}_${item.id}`,staticClass:"text-center",class:{
                'error--text font-weight-medium':
                  item.missing_quantity && item.missing_quantity > 0,
              }},[_c('td',[_vm._v(_vm._s(item.sku))]),_c('td',[_c('ImageViewer',{attrs:{"url":item.url_images || require('@/assets/common/no-image.jpg')}})],1),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.quantity))])])}),0)]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }