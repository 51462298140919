<template>
  <div>
    <v-row dense>
      <v-col cols="4">
        <select-warehouse-model
          v-model="id_warehouse"
          :label="$t('labels.warehouse')"
          @change="onWarehouseChange"
          :single-line="false"
        ></select-warehouse-model>
      </v-col>
      <v-col cols="8">
        <input-qr-scan-model
          ref="inputQr"
          v-model="code"
          class="mb-5"
          :label="$t('labels.qr_sku_barcode')"
          @keyupEnter="onCheck"
          :disabled="!id_warehouse"
        ></input-qr-scan-model>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" md="4">
        <v-simple-table class="table-padding-2 mt-3">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.suitable_basket") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.employee") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.missing_quantity") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(item, index) in baskets"
                :key="`${index}_${item.id}`"
                :class="{
                  'error--text font-weight-medium':
                    item.missing_quantity && item.missing_quantity > 0,
                }"
              >
                <td>{{ item.basket_code }}</td>
                <td>
                  {{ item.employee_name }} <br />
                  <small>
                    {{ item.id_pickup_command }} -
                    {{ formatDateTime(item.pickup_at) }}
                  </small>
                </td>
                <td>{{ item.id_find_goods ? "Yes" : "No" }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col cols="12" md="8">
        <v-simple-table class="table-padding-2 mt-3">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.sku") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.image") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.goods") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.quantity") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(item, index) in items"
                :key="`${index}_${item.id}`"
                :class="{
                  'error--text font-weight-medium':
                    item.missing_quantity && item.missing_quantity > 0,
                }"
              >
                <td>{{ item.sku }}</td>
                <td>
                  <ImageViewer
                    :url="
                      item.url_images || require('@/assets/common/no-image.jpg')
                    "
                  />
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.quantity }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { formatDateTime } from "@/libs/helpers";

export default {
  name: "FindPickupBasket",
  components: {
    ImageViewer: () => import("@/components/goods/ImageViewer"),
  },
  data: () => ({
    code: null,
    id_warehouse: null,
    baskets: [],
    items: [],
  }),
  created() {},
  mounted() {
    setTimeout(() => {
      this.$refs.inputQr_1.focusInput();
    }, 500);
  },
  methods: {
    formatDateTime,
    onWarehouseChange() {
      this.focusInputQr();
      this.resetValue();
    },
    focusInputQr() {
      setTimeout(() => {
        this.$refs.inputQr.focusInput();
      }, 200);
    },
    resetValue() {},
    async onCheck() {
      try {
        const { data } = await httpClient.post("/goods/v1/get-item-or-goods", {
          code: this.code,
          id_warehouse: this.id_warehouse,
        });
        const checkItem = [...this.items].find(
          (item) => item.id_goods == data.id_goods
        );
        if (!checkItem) {
          this.items.push({
            id_goods: data.id_goods,
            sku: data.sku,
            customer_goods_barcode: data.customer_goods_barcode,
            name: data.name,
            description: data.description,
            size: data.size,
            url_images: data.url_images,
            available_quantity: data.available_quantity,
            quantity: 1,
          });
        } else {
          const itemIndex = [...this.items].findIndex(
            (item) => item.id_goods == data.id_goods
          );
          const items = [...this.items];
          items[itemIndex].quantity = checkItem.quantity + 1;
          this.items = [...items];
        }
        this.code = null;
        document.getElementById("success_sound_player").play();
        this.findBasket();
      } catch (e) {
        this.code = null;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },
    async findBasket() {
      try {
        const { data } = await httpClient.post(
          "/common/v1/find-pickup-basket",
          {
            items: this.items,
            id_warehouse: this.id_warehouse,
          }
        );
        this.baskets = [...data];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
